import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Modal, Space, Popconfirm, message, Col, Row, Tooltip } from 'antd';
import { EditOutlined, SnippetsOutlined, DeleteOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { getAllCursoFortalecimiento, deleteMatriculado } from '../../../../services/Producto_3/Fort_Capacidades/CursosCiclosVida/cursoFortalecimientoCVService';
import { useSelector } from 'react-redux';


import { selectAppPermissions } from '../../../../store/auth/selectors';
import './CursoCicloVida.css';
import FormCV from './CursoCicloVidaForm';
import { useNavigate } from "react-router-dom";


const CursoCicloVida = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisibleForm, setIsModalVisible] = useState(false);
    const [codRegistro, setCodRegistro] = useState(null);
    const [filterCedula, setFilterCedula] = useState('');
    const [filterNombre, setFilterNombre] = useState('');
    const [filterCelular, setFilterCelular] = useState('');
    const [activeTabKey, setActiveTabKey] = useState("1");
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [resetSignal, setResetSignal] = useState(false);



    const appName = 'curso-fortalecimiento';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionInsert = permissions.priv_insert === 'Y';
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionDelete = permissions.priv_delete === 'Y';

    const navigate = useNavigate();

    const fetchUsuarios = async () => {
        setLoading(true);
        try {
            const users = await getAllCursoFortalecimiento();
            setData(users);
        } catch (error) {
            message.error('Error al cargar los usuarios');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsuarios();
    }, []);

    const handleDelete = async (record) => {
        if (!record.oid) {
            message.error('No se encontró el ID del participante a eliminar.');
            return;
        }
        try {
            await deleteMatriculado(record.oid);
            message.success('Participante eliminado correctamente');
            fetchUsuarios();
        } catch (error) {
            message.error('Error al eliminar el participante, tiene sesiones asociadas');
            console.error('Error al eliminar:', error);
        }
    };

    const showUniminuto = () => {
        navigate('/uniminuto'); // Redirige manteniendo el historial
    };

    const handleSuccess = () => {
        setIsModalVisible(false);
        setCodRegistro(null);
        fetchUsuarios();
    };

    const columns = [
        { title: 'Autorizacion tratamiento de datos', dataIndex: 'periodo_saradap', key: 'periodo_saradap' },
        {
            title: 'Año de nacimiento',
            dataIndex: 'fecha_actividad',
            key: 'fecha_actividad',
            render: (text) => text, // El valor ya es el año
        },
        { title: 'Cohorte', dataIndex: 'spriden_pidm', key: 'spriden_pidm' },
        { title: 'Género', dataIndex: 'id_prospecto', key: 'id_prospecto' },
        { title: 'Identificación', dataIndex: 'identificacion', key: 'identificacion' },
        { title: 'Nombre del matriculado', dataIndex: 'nombre_matriculado', key: 'nombre_matriculado' },
        { title: 'Teléfono', dataIndex: 'telefono', key: 'telefono' },
        { title: 'Correo electrónico', dataIndex: 'email', key: 'email' },
        { title: 'Tipo de documento', dataIndex: 'num_doc', key: 'num_doc' },
        { title: 'Edad', dataIndex: 'snp', key: 'snp' },
        { title: 'Grupo étnico', dataIndex: 'cos_insc', key: 'cos_insc' },
        { title: 'Estado inscripción', dataIndex: 'estado_insc', key: 'estado_insc' },
        { title: 'Estado admisión', dataIndex: 'estado_admision', key: 'estado_admision' },
        { title: 'Municipio admisión', dataIndex: 'cod_tip_adm', key: 'cod_tip_adm' },
        { title: 'Departamento admisión', dataIndex: 'tipo_adm', key: 'tipo_adm' },
        { title: 'Aplicación No.', dataIndex: 'apl_no', key: 'apl_no' },
        { title: 'Código materia', dataIndex: 'Saradap_Majr_Code', key: 'Saradap_Majr_Code' },
        { title: 'Usuario', dataIndex: 'usuario', key: 'usuario' },
        {
            title: 'Acciones',
            key: 'acciones',
            fixed: 'right',
            render: (_, record) => (
                <Space size="middle">
                    {permissionUpdate && (
                        <Tooltip title="Editar participante">
                            <Button
                                icon={<EditOutlined />}
                                type="primary"
                                style={{ backgroundColor: '#f0ab18' }}
                                onClick={() => showModal(record)}
                            />
                        </Tooltip>
                    )}
                    {permissionDelete && (
                        <Tooltip title="Eliminar participante">
                            <Popconfirm
                                title="¿Estás seguro de eliminar este usuario?"
                                onConfirm={() => handleDelete(record)}
                                okText="Sí"
                                cancelText="No"
                            >
                                <Button danger icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];
    

    const filteredData = data.filter(user =>
        (!filterCedula || user.identificacion?.includes(filterCedula)) &&
        (!filterNombre || user.nombre_matriculado?.toLowerCase().includes(filterNombre.toLowerCase())) &&
        (!filterCelular || user.telefono?.includes(filterCelular))
    ).map((user, index) => ({
        ...user,
        key: user.oid || `temp-key-${index}`,
    }));
    

    const handleClearFilters = () => {
        setFilterCedula('');
        setFilterNombre('');
        fetchUsuarios(); // Recargar los datos si es necesario
    };


    const showModal = (data) => {
        setIsModalVisible(true);
        setCodRegistro(data);
        setActiveTabKey("1");
    };

    const handleCancelSesion = () => {
        if (hasUnsavedChanges) {
            Modal.confirm({
                title: '¿Estás seguro de cerrar?',
                content: 'Si cierras el modal, perderás toda la información ingresada.',
                okText: 'Sí, cerrar',
                cancelText: 'No, regresar',
                onOk: () => {
                    setIsModalVisible(false);
                    setCodRegistro(null);
                    setActiveTabKey("1");
                    setResetSignal(!resetSignal);
                },
            });
        } else {
            setIsModalVisible(false);
            setCodRegistro(null);
            setActiveTabKey("1");
            setResetSignal(!resetSignal);
        }
    };

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    return (
        <div className="container">
            <div className="filtro-container">
                <Row gutter={24}
                    justify="start" align="middle" style={{ marginBottom: 16 }}>
                    <Col span={3} style={{ marginRight: 30 }}>
                        <Input
                            placeholder="Filtrar por Cédula"
                            value={filterCedula || ''}
                            onChange={(e) => setFilterCedula(e.target.value)}
                            className="filtro-item"
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col span={3} style={{ marginRight: 30 }} >
                        <Input
                            placeholder="Filtrar por Nombre"
                            value={filterNombre || ''}
                            onChange={(e) => setFilterNombre(e.target.value)}
                            className="filtro-item"
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col span={3} style={{ marginRight: 30 }}>
                        <Input
                            placeholder="Filtrar por Celular"
                            value={filterCelular || ''}
                            onChange={(e) => setFilterCelular(e.target.value)}
                            className="filtro-item"
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col span={4}>
                        <Tooltip title="Limpiar filtros">
                            <Button
                                icon={<SearchOutlined style={{ color: '#000000' }} />}
                                onClick={handleClearFilters}
                                style={{ backgroundColor: '#1677FF', color: '#ffffff', width: '100%' }}
                            >
                                Limpiar Filtros
                            </Button>
                        </Tooltip>
                    </Col>
                    <Col span={4}>
                        <Tooltip title="Crear Participantes">
                            <Button
                                icon={<PlusOutlined />}
                                onClick={() => showModal(null)}
                                style={{ backgroundColor: '#3BB44A', color: '#ffffff' }}
                            >
                                Registrar Matrícula
                            </Button>
                        </Tooltip>
                    </Col>

                    <Col span={4}>
                        <Tooltip title="Ver información de las sesiones del curso">
                            <Button
                                icon={<SnippetsOutlined />}
                                onClick={() => showUniminuto()}
                                style={{ backgroundColor: '#f1ab17', color: '#ffffff' }}
                            >
                                Sesiones del curso
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </div>


            <div className="participant-count-container">
                <h1 className="participant-count-title">
                    Número de Matriculados: <span className="participant-count-number">{filteredData.length}</span>
                </h1>
            </div>
            <div className="tabla-container">
                <Table
                    className="table-curso-fortalecimiento"
                    columns={columns}
                    dataSource={filteredData}
                    rowKey="key"
                    loading={loading}
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: filteredData.length,
                        showSizeChanger: true,
                    }}
                    onChange={handleTableChange}
                    scroll={{ x: 'max-content' }}
                    size="small"
                    locale={{
                        emptyText: filteredData.length === 0 ? 'No se han registrado usuarios aún' : 'No hay usuarios disponibles en este momento',
                    }}
                />
            </div>
            <Modal
                title={'Matriculado'}
                open={isModalVisibleForm}
                width={1600}
                onCancel={handleCancelSesion}
                footer={null}
            >
                <FormCV registroData={codRegistro} resetSignal={resetSignal} onSuccess={handleSuccess} onPendingChanges={setHasUnsavedChanges} activeTabKey={activeTabKey} setActiveTabKey={setActiveTabKey} />
            </Modal>
        </div>
    );
};
export default CursoCicloVida;