import React, { useEffect, useState } from 'react';
import { Modal, Table, Button, Typography, Collapse } from 'antd';
import PlanesPedagogicosService from "../../../../services/inicio/repositorio/planesPedagogicosService";
import { FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';

import './PedagogicalPlanModal.css';
import DownloadPedagogicalPlanPDF from './DownloadPedagogicalPlanPDF';
import DownloadPedagogicalPlanXLSX from './DownloadPedagogicalPlanXLSX';
const { Text, Title } = Typography;
const { Panel } = Collapse;

const formatValue = (value) => {
  if (!value) return 'N/A';

  if (typeof value === 'string') {
    return value.split('\n').map((line, i) => (
      <React.Fragment key={i}>
        {line}
        <br />
      </React.Fragment>
    ));
  }
  return value.toString();
};

const PedagogicalPlanModal = ({ open, onCancel, data }) => {
  const [dataPP, setDataPP] = useState({});
  const [showPdfGenerator, setShowPdfGenerator] = useState(false);
  const [showXlsxGenerator, setShowXlsxGenerator] = useState(false);

  useEffect(() => {
    if (data?.planesPP) {
      const fetchData = async () => {
        try {
          const response = await PlanesPedagogicosService.getPlanID(data.planesPP);
          setDataPP(response); // guardamos el objeto directamente
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  }, [data.planesPP]);

  const fieldLabels = {
    codigoProyecto: 'Código Proyecto',
    subregion: 'Subregión',
    municipio: 'Municipio',
    nombreProyecto: 'Nombre del Proyecto',
    lineaProductiva: 'Línea Productiva',
    subsector: 'Subsector',
    FactibilidadProductoresFamilias: 'Productores / Familias',
    factibilidadVisitas: 'Visitas',
    factibilidadConformacionECA: 'Conformación ECA',
    factibilidadSesionesECA: 'Sesiones ECA',
    factibilidadNumeroOrg: 'Número de Organizaciones',
    encuentrosAcompanamientoOrg: 'Encuentros Acompañamiento',
    tematicasAcompanamientoOrg: 'Temáticas Acompañamiento',
    encuestaDHAA: 'Encuesta DHAA',
    aplicacionInstrumento: 'Aplicación Instrumento',
    prediosIPPTA: 'Predios IPPTA',
    EnfoqueGenero: 'Enfoque de Género',
    enfoqueEtnico: 'Enfoque Étnico',
    enfoqueReparador: 'Enfoque Reparador',
    enfoqueCiclodeVida: 'Enfoque Ciclo de Vida',
    Observaciones: 'Observaciones',
    // Para sesiones (Sesion1, Sesion2, ..., Sesion10)
    ...Object.fromEntries(Array.from({ length: 10 }, (_, i) => [`Sesion${i + 1}`, `Sesión ${i + 1}`]))
  };


  const sections = [
    {
      key: 'informacionGeneral',
      title: 'Información General',
      fields: [
        'codigoProyecto', 'subregion', 'municipio', 'nombreProyecto',
        'lineaProductiva', 'subsector'
      ]
    },
    {
      key: 'factibilidad',
      title: 'Factibilidad',
      fields: [
        'FactibilidadProductoresFamilias', 'factibilidadVisitas',
        'factibilidadConformacionECA', 'factibilidadSesionesECA',
        'factibilidadNumeroOrg'
      ]
    },
    {
      key: 'acompanamiento',
      title: 'Acompañamiento',
      fields: [
        'encuentrosAcompanamientoOrg', 'tematicasAcompanamientoOrg',
        'encuestaDHAA', 'aplicacionInstrumento', 'prediosIPPTA'
      ]
    },
    {
      key: 'sesiones',
      title: 'Sesiones',
      fields: Array.from({ length: 10 }, (_, i) => `Sesion${i + 1}`)
    },
    {
      key: 'enfoques',
      title: 'Enfoques',
      fields: [
        'EnfoqueGenero', 'enfoqueEtnico', 'enfoqueReparador', 'enfoqueCiclodeVida'
      ]
    },
    {
      key: 'observaciones',
      title: 'Observaciones',
      fields: ['Observaciones']
    }
  ];

  const handleDownloadPDF = () => {
    // Activamos el componente de generación de PDF
    setShowPdfGenerator(true);
    // Lo desactivamos después de un breve tiempo
    setTimeout(() => setShowPdfGenerator(false), 100);
  };

  const handleDownloadXML = () => {
    setShowXlsxGenerator(true);
    setTimeout(() => setShowXlsxGenerator(false), 100);
  };

  return (
    <div>
      <Modal
        title={<Title level={3}>Plan Pedagógico - {dataPP?.codigoProyecto}</Title>}
        open={open}
        onCancel={onCancel}
        footer={[
          <Button
            key="download-pdf"
            type="primary"
            icon={<FilePdfOutlined />}
            style={{ backgroundColor: '#d32f2f', borderColor: '#d32f2f' }}
            onClick={handleDownloadPDF}
          >
            Descargar PDF
          </Button>,
          <Button
            key="download-xml"
            icon={<FileExcelOutlined />}
            style={{ backgroundColor: '#2e7d32', borderColor: '#2e7d32', color: 'white' }}
            onClick={handleDownloadXML}
          >
            Descargar XML
          </Button>,
          <Button key="close" onClick={onCancel}>
            Cerrar
          </Button>,
        ]}
        width="90%"
        style={{ top: 20 }}
        destroyOnClose
      >
        <Collapse accordion defaultActiveKey={['informacionGeneral']} className="collate-background-PedagogicoPlan">
          {sections.map(section => {
            const dataSource = section.fields.map((field) => ({
              key: field,
              field,
              value: dataPP[field]
            }));

            return (
              <Panel header={section.title} key={section.key}>
                <Table
                  className="pedagogical-plan-table"
                  columns={[
                    {
                      title: 'Campo',
                      dataIndex: 'field',
                      key: 'field',
                      width: '30%',
                      render: (text) => <Text strong>{fieldLabels[text] || text}</Text>,

                    },
                    {
                      title: 'Valor',
                      dataIndex: 'value',
                      key: 'value',
                      render: (text) => (
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                          {formatValue(text)}
                        </div>
                      ),
                    },
                  ]}
                  dataSource={dataSource}
                  pagination={false}
                  bordered
                  size="middle"
                  showHeader={false}
                />
              </Panel>
            );
          })}
        </Collapse>
      </Modal>
      {showPdfGenerator && (
        <DownloadPedagogicalPlanPDF
          dataPDF={dataPP}
          onFinish={() => {
            setShowPdfGenerator(false);
          }}
        />
      )}
      {showXlsxGenerator && (
        <DownloadPedagogicalPlanXLSX
        dataXLSX={dataPP}
        onFinish={() => {
          setShowXlsxGenerator(false);
        }}
      />      
      )}
    </div>
  );
};

export default PedagogicalPlanModal;
