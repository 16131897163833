import React, { useEffect } from 'react';
import { pdf, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Etiquetas amigables para mostrar
const fieldLabels = {
  codigoProyecto: 'Código Proyecto',
  subregion: 'Subregión',
  municipio: 'Municipio',
  nombreProyecto: 'Nombre del Proyecto',
  lineaProductiva: 'Línea Productiva',
  subsector: 'Subsector',
  FactibilidadProductoresFamilias: 'Productores / Familias',
  factibilidadVisitas: 'Visitas',
  factibilidadConformacionECA: 'Conformación ECA',
  factibilidadSesionesECA: 'Sesiones ECA',
  factibilidadNumeroOrg: 'Número de Organizaciones',
  encuentrosAcompanamientoOrg: 'Encuentros Acompañamiento',
  tematicasAcompanamientoOrg: 'Temáticas Acompañamiento',
  encuestaDHAA: 'Encuesta DHAA',
  aplicacionInstrumento: 'Aplicación Instrumento',
  prediosIPPTA: 'Predios IPPTA',
  EnfoqueGenero: 'Enfoque de Género',
  enfoqueEtnico: 'Enfoque Étnico',
  enfoqueReparador: 'Enfoque Reparador',
  enfoqueCiclodeVida: 'Enfoque Ciclo de Vida',
  Observaciones: 'Observaciones',
  ...Object.fromEntries(Array.from({ length: 10 }, (_, i) => [`Sesion${i + 1}`, `Sesión ${i + 1}`]))
};

// Estilos para el PDF
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    fontFamily: 'Helvetica',
  },
  header: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColTitle: {
    width: '30%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 4,
    backgroundColor: '#eeeeee',
    fontWeight: 'bold',
  },
  tableColValue: {
    width: '70%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 4,
  },
});

const PDFContent = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>Plan Pedagógico</Text>

      <View style={styles.table}>
        {Object.entries(data).map(([key, value]) => {
          if (value === "" || value === null) return null;
          const label = fieldLabels[key] || key;

          return (
            <View key={key} style={styles.tableRow}>
              <Text style={styles.tableColTitle}>{label}</Text>
              <Text style={styles.tableColValue}>{String(value)}</Text>
            </View>
          );
        })}
      </View>
    </Page>
  </Document>
);

const DownloadPedagogicalPlanPDF = ({ dataPDF, onFinish }) => {
  useEffect(() => {
    const generatePDF = async () => {
      const blob = await pdf(<PDFContent data={dataPDF} />).toBlob();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `Plan_Pedagogico_${dataPDF.oid}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      if (onFinish) onFinish();
    };

    generatePDF();
  }, [dataPDF, onFinish]);

  return null;
};

export default DownloadPedagogicalPlanPDF;
