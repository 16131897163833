import axios from "axios";
import config from "../../../config"; 

const baseUrl = config.baseUrl;
const endpoint = `${baseUrl}/api/planesPedagogicosInicio`; 

const PlanesPedagogicosService = {
  getPlanID: async (id) => {
    try {
      const response = await axios.get(`${endpoint}/${id}`); 
      return response.data;
    } catch (error) {
      console.error(`Error fetching document with ID ${id}:`, error);
      throw error;
    }
  },
};

export default PlanesPedagogicosService;
