import { useEffect } from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const DownloadPedagogicalPlanXLSX = ({ dataXLSX, onFinish }) => {
  useEffect(() => {
    const generateExcel = async () => {
      const fieldLabels = {
        codigoProyecto: 'Código Proyecto',
        subregion: 'Subregión',
        municipio: 'Municipio',
        nombreProyecto: 'Nombre del Proyecto',
        lineaProductiva: 'Línea Productiva',
        subsector: 'Subsector',
        FactibilidadProductoresFamilias: 'Productores / Familias',
        factibilidadVisitas: 'Visitas',
        factibilidadConformacionECA: 'Conformación ECA',
        factibilidadSesionesECA: 'Sesiones ECA',
        factibilidadNumeroOrg: 'Número de Organizaciones',
        encuentrosAcompanamientoOrg: 'Encuentros Acompañamiento',
        tematicasAcompanamientoOrg: 'Temáticas Acompañamiento',
        encuestaDHAA: 'Encuesta DHAA',
        aplicacionInstrumento: 'Aplicación Instrumento',
        prediosIPPTA: 'Predios IPPTA',
        EnfoqueGenero: 'Enfoque de Género',
        enfoqueEtnico: 'Enfoque Étnico',
        enfoqueReparador: 'Enfoque Reparador',
        enfoqueCiclodeVida: 'Enfoque Ciclo de Vida',
        Observaciones: 'Observaciones',
        ...Object.fromEntries(Array.from({ length: 10 }, (_, i) => [`Sesion${i + 1}`, `Sesión ${i + 1}`]))
      };

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Plan Pedagógico ' + dataXLSX.codigoProyecto);

      const validEntries = Object.entries(dataXLSX)
        .filter(([key, value]) => value !== "" && value !== null && fieldLabels[key]);

      const headers = validEntries.map(([key]) => fieldLabels[key]);
      const values = validEntries.map(([, value]) => value);

      worksheet.addRow(headers);
      worksheet.addRow(values);

      // Estilos encabezados
      headers.forEach((_, i) => {
        const cell = worksheet.getRow(1).getCell(i + 1);
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '4F81BD' } };
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      });

      // Estilos valores
      values.forEach((_, i) => {
        const cell = worksheet.getRow(2).getCell(i + 1);
        cell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
      });

      // Ajustar ancho y alto
      worksheet.columns.forEach((column) => {
        column.width = 40; // ~300px
      });

      worksheet.getRow(1).height = 30;        // Encabezado fijo
      worksheet.getRow(2).height = undefined; // Altura automática para contenido

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), `Plan_Pedagogico_${dataXLSX.codigoProyecto}.xlsx`);

      if (onFinish) onFinish();
    };

    generateExcel();
  }, [dataXLSX, onFinish]);

  return null;
};

export default DownloadPedagogicalPlanXLSX;
