// Importación de librerías y componentes necesarios
import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { Drawer, Modal, Card, Button, Tooltip, Pagination, Row, Col, message, Checkbox, Input, Dropdown, Menu, Typography, Select, Divider, Radio, Space, Tabs, Spin } from "antd";
import {
  FilePdfOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileZipOutlined,
  FileMarkdownOutlined,
  FilePptOutlined,
  FileUnknownOutlined, SettingOutlined, OrderedListOutlined, UnorderedListOutlined, ClockCircleOutlined, SearchOutlined, EllipsisOutlined, StarOutlined, LeftOutlined, GlobalOutlined, EditOutlined, MoreOutlined, FileOutlined, FolderOpenOutlined, FolderOutlined, HistoryOutlined, StarFilled, HeartOutlined, HolderOutlined, AppstoreOutlined, FilterOutlined, FileTextOutlined, CloseOutlined, RightOutlined, DownloadOutlined, DeleteOutlined
} from "@ant-design/icons";


import RepositorioService from "../../../services/inicio/repositorio/repositorioDocumentoService";
import RepositorioPOAProyecto from "../../../services/inicio/repositorio/repositorioPOAService.js";
import RenombrarCarpeta from "./GestorRespositorio/RenombrarCarpeta";
import { downloadFile, getViewFileUrl, deleteFolder, deleteFile, downloadZip } from '../../../services/inicio/repositorio/repositorioDescargarVerService';
import CrearCarpeta from "./GestorRespositorio/CreateFolder";
import { useSelector } from "react-redux";
import { selectAppPermissions } from "../../../store/auth/selectors.js";
import ListaRepositorio from "../../Inicio/Repositorio/GestorRespositorio/ListaRepositorio.jsx"
import PedagogicalPlanModal from './PlanPedagogicoInicial/PedagogicalPlanModal.jsx';

// Importación de estilos personalizados
import "./FileExplorer.css";


// Componentes de Ant Design utilizados
const { Search } = Input;
const { Option } = Select;
const { Text, Title } = Typography;
const { SubMenu } = Menu;

// Estructura inicial del repositorio
const initialStructure = {
  id: "root",
  name: "Repositorio",
  type: "folder",
  path: "/var/www/html/backend/DocumentosFAO/Repositorio/",
  children: [],
};


// Componente principal del repositorio de documentos
const RepositorioDocumento = ({ open, onCloseMetodo, proyectoCodigo }) => {
  // Estados del componente
  const [dataInicial, setDataInicial] = useState(initialStructure);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Elementos por página
  const [searchQuery, setSearchQuery] = useState(""); // Búsqueda
  const [sortBy, setSortBy] = useState("name"); // Ordenar por
  const [sortOrder, setSortOrder] = useState("asc"); // Orden ascendente o descendente
  const [viewMode, setViewMode] = useState("list"); // Modo de vista
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [filterType, setFilterType] = useState("all"); // Tipo de filtro
  const [activeTab, setActiveTab] = useState("all"); // Pestaña activa
  const [isLoading, setIsLoading] = useState(false); // Estado de carga
  const [favorites, setFavorites] = useState([]); // Elementos favoritos
  const [hoveredFolder, setHoveredFolder] = useState(null); // Carpeta sobre la que se pasa el mouse
  const [selectedItems, setSelectedItems] = useState([]); // Elementos seleccionados
  const totalPages = Math.ceil(initialStructure.length / itemsPerPage);
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);
  const [modalVisiblePalnPedagogico, setModalVisiblePalnPedagogico] = useState(false);
  const [selectedFilePalnPedagogico, setSelectedFilePalnPedagogico] = useState(null);


  const [selectedFolder, setSelectedFolder] = useState(null);
  const [rootFileCount, setRootFileCount] = useState(0);
  const [totalFolderCount, setTotalFolderCount] = useState(0);

  const [selectedPath, setSelectedPath] = useState("");
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [filteredDataFiles, setFilteredDataFiles] = useState([]);
  const [menuVisible, setMenuVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const [loading, setLoading] = useState(false);

  // Estados para el modal de eliminación de carpetas
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);

  // Estados para el modal de eliminación de archivos
  const [fileToDelete, setFileToDelete] = useState(null);
  const [deleteFileModalVisible, setDeleteFileModalVisible] = useState(false);

  const [mostrarLista, setMostrarLista] = useState(true);


  const [selectedKey, setSelectedKey] = useState(null);
  const [openKeys, setOpenKeys] = useState([]); // Estado para manejar submenús abiertos


  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [folderToRename, setFolderToRename] = useState(null);

  const user = useSelector((state) => state.auth.user);
  const userLogin = user.username;
  const appName = 'repositorio_documental';
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  const permissionInsert = permissions.priv_insert === 'Y';
  const permissionDelete = permissions.priv_delete === 'Y';
  const permissionUpdate = permissions.priv_update === 'Y';
  const permissionExport = permissions.priv_export === 'Y';
  const permissionView = permissions.priv_print === 'Y';
  const permissionPrint = permissions.priv_print === 'Y';

  const convertToBytes = (sizeStr) => {
    if (!sizeStr) return 0;
    const units = { B: 1, KB: 1024, MB: 1024 ** 2, GB: 1024 ** 3 };
    const match = sizeStr.match(/^([\d.]+)\s*(B|KB|MB|GB)$/i);
    if (!match) return 0; // Si no coincide con el formato esperado, devolver 0
    const [_, value, unit] = match;
    return parseFloat(value) * (units[unit.toUpperCase()] || 1);
  };

  const formatSize = (bytes) => {
    if (bytes >= 1024 ** 3) return `${(bytes / 1024 ** 3).toFixed(2)} GB`;
    if (bytes >= 1024 ** 2) return `${(bytes / 1024 ** 2).toFixed(2)} MB`;
    if (bytes >= 1024) return `${(bytes / 1024).toFixed(2)} KB`;
    return `${bytes} B`;
  };

  const updateStats = useCallback((data) => {
    let fileCount = 0;
    let folderCount = 0;
    let totalSizeBytes = 0;

    const countItems = (items) => {
      if (!Array.isArray(items)) return;

      items.forEach((item) => {
        if (item.type === "file") {
          fileCount++;

          // Validamos el tipo de size
          if (typeof item.size === "string") {
            totalSizeBytes += convertToBytes(item.size);
          } else if (typeof item.size === "number") {
            totalSizeBytes += item.size;
          }
        } else if (item.type === "folder") {
          folderCount++;
          if (item.children) {
            countItems(item.children);
          }
        }
      });
    };

    countItems(data);

    setStats((prevStats) => {
      const formattedSize = formatSize(totalSizeBytes);
      if (
        prevStats.totalFiles === fileCount &&
        prevStats.totalFolders === folderCount &&
        prevStats.totalSize === formattedSize
      ) {
        return prevStats;
      }
      return {
        ...prevStats,
        totalFiles: fileCount,
        totalFolders: folderCount,
        totalSize: formattedSize,
      };
    });
  }, []);


  const [stats, setStats] = useState({
    totalFiles: 0,
    totalFolders: 0,
    totalSize: "0 MB",
  });

  const InicializarDatosFolder = useCallback(() => {
    // Código de inicialización de carpetas aquí...
  }, []);

  const isFetching = useRef(false);

  const fetchDocuments = useCallback(async () => {
    if (isFetching.current) return;
    isFetching.current = true;
    try {
      let data = [];
      if (proyectoCodigo) {
        setMostrarLista(false);
        const codigoLimpio = proyectoCodigo.replace(/^P0?|P\d+/, "");
        data = await RepositorioPOAProyecto.getDato(codigoLimpio);
      } else {
        data = await RepositorioService.getDocuments(userLogin);
      }
      setSelectedFolder("Repositorio Documental");
      setBreadcrumbPath([{ id: "root", name: "repositorio" }]);
      const carpetasFiltradas = filtrarCarpetas(data, 1);
      const archivosFiltrados = filtrarCarpetas(data, 2);
      setFilteredDataFiles(archivosFiltrados);
      setFilteredFolders(carpetasFiltradas);
      const rootFileCount = data.filter((item) => item.type === "file").length;
      const totalFolderCount = data.filter((item) => item.type === "folder").length;
      setRootFileCount(rootFileCount);
      setTotalFolderCount(totalFolderCount);

      setDataInicial((prevData) =>
        JSON.stringify(prevData) === JSON.stringify(data) ? prevData : { ...initialStructure, children: data }
      );
      InicializarDatosFolder();
      updateStats(data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      isFetching.current = false;
    }
  }, [userLogin, InicializarDatosFolder, updateStats, proyectoCodigo]);

  useEffect(() => {
    if (userLogin) {
      fetchDocuments();
    }
  }, [userLogin, fetchDocuments]);



  // Función para cerrar el Drawer y restaurar estado
  const onClose = () => {
    onCloseMetodo();
    // Aquí reseteas los states que quieras re-inicializar
    setBreadcrumbPath([{ id: "root", name: "repositorio" }]);
    setSelectedFolder("Repositorio Documental");
    setActiveTab("all");
    setOpenKeys([]);
    setSelectedItems([]);
    setSelectedPath("");

    // ... resetea cualquier otro estado que necesites
  };

  // Configuración del menú de filtros
  const filterMenu = {
    items: [
      {
        key: "file-type",
        label: (
          <div className="menu-section-repositorio" style={{ padding: "12px" }} onClick={(e) => e.stopPropagation()}>
            <Typography.Text strong className="menu-title-repositorio">Tipo de archivo</Typography.Text>
            <Select
              value={filterType}
              onChange={(value) => setFilterType(value)}
              className="select-repositorio"
              style={{ width: "100%", marginTop: "5px" }}
            >
              <Option value="all">Todos los tipos</Option>
              <Option value="pdf">PDF</Option>
              <Option value="docx">Word (DOCX)</Option>
              <Option value="xlsx">Excel (XLSX)</Option>
              <Option value="jpg">Imagen (JPG)</Option>
              <Option value="png">Imagen (PNG)</Option>
              <Option value="url">Enlaces URL</Option>
            </Select>
          </div>
        ),
      },
      { key: "divider-1", type: "divider" },
      {
        key: "sort-by",
        label: (
          <div className="menu-section-repositorio" style={{ padding: "5px" }} onClick={(e) => e.stopPropagation()}>
            <Typography.Text strong className="menu-title-repositorio">Ordenar por</Typography.Text>
            <Radio.Group
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="radio-group-repositorio"
            >
              <Radio className="radio-option-repositorio" value="name">Nombre</Radio>
              <Radio className="radio-option-repositorio" value="date">Fecha</Radio>
              <Radio className="radio-option-repositorio" value="size">Tamaño</Radio>
            </Radio.Group>
          </div>
        ),
      },
      { key: "divider-2", type: "divider" },
      {
        key: "order",
        label: (
          <div className="menu-section-repositorio" style={{ padding: "12px" }} onClick={(e) => e.stopPropagation()}>
            <Typography.Text strong className="menu-title-repositorio">Orden</Typography.Text>
            <Radio.Group
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              className="radio-group-repositorio"
            >
              <Space direction="vertical" size="small">
                <Radio className="radio-option-repositorio" value="asc">Ascendente (A-Z)</Radio>
                <Radio className="radio-option-repositorio" value="desc">Descendente (Z-A)</Radio>
              </Space>
            </Radio.Group>
          </div>
        ),
      },
    ],
  };

  // Función para filtrar carpetas
  const filtrarCarpetas = (data, option) => {
    if (!Array.isArray(data)) return [];
    return data.filter((item) =>
      option === 1 ? item.type === "folder" : item.type !== "folder"
    );
  };

  // Configuración del menú de vista
  const items = [
    {
      key: "list",
      label: (
        <div onClick={() => setViewMode("list")}>
          <UnorderedListOutlined /> Lista
        </div>
      ),
    },
    {
      key: "grid",
      label: (
        <div onClick={() => setViewMode("grid")}>
          <HolderOutlined /> Cuadrícula
        </div>
      ),
    },
    {
      key: "compact",
      label: (
        <div onClick={() => setViewMode("compact")}>
          <FileTextOutlined style={{ fontSize: "12px" }} /> Compacta
        </div>
      ),
    },
    { key: "divider", type: "divider" },
    {
      key: "items-per-page",
      label: (
        <div>
          Items por página
          <Select
            value={itemsPerPage}
            style={{ width: 70, marginLeft: "8px" }}
            size="small"
            onChange={setItemsPerPage}
            onClick={(e) => e.stopPropagation()}
          >
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
          </Select>
        </div>
      ),
    },
  ];



  const getFileIconByExtension = (extWithDot) => {
    const ext = extWithDot?.replace('.', '').toLowerCase();

    switch (ext) {
      case 'pdf':
        return <FilePdfOutlined className="me-3 fs-5" style={{ color: '#d32f2f' }} />;
      case 'doc':
      case 'docx':
        return <FileWordOutlined className="me-3 fs-5" style={{ color: '#1976d2' }} />;
      case 'xls':
      case 'xlsx':
        return <FileExcelOutlined className="me-3 fs-5" style={{ color: '#388e3c' }} />;
      case 'ppt':
      case 'pptx':
        return <FilePptOutlined className="me-3 fs-5" style={{ color: '#e64a19' }} />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <FileImageOutlined className="me-3 fs-5" style={{ color: '#6a1b9a' }} />;
      case 'zip':
      case 'rar':
        return <FileZipOutlined className="me-3 fs-5" style={{ color: '#ff8f00' }} />;
      case 'txt':
        return <FileTextOutlined className="me-3 fs-5" style={{ color: '#455a64' }} />;
      case 'md':
        return <FileMarkdownOutlined className="me-3 fs-5" style={{ color: '#607d8b' }} />;
      default:
        return <FileUnknownOutlined />;
    }
  };




  // Función para aplanar archivos en una estructura
  const flattenFiles = (folder) => {
    let files = [];
    folder.children.forEach((item) => {
      if (item.type === "file" || item.type === "link") {
        files.push(item);
      } else if (item.type === "folder") {
        files = files.concat(flattenFiles(item));
      }
    });
    return files;
  };

  // Archivos aplanados y paginados
  const allFiles = flattenFiles(initialStructure);

  const filteredFiles = allFiles.filter((file) => {
    if (filterType === "all") return true;
    if (filterType === "pdf" && file.name.endsWith(".pdf")) return true;
    if (filterType === "docx" && file.name.endsWith(".docx")) return true;
    if (filterType === "xlsx" && file.name.endsWith(".xlsx")) return true;
    if (filterType === "jpg" && file.name.endsWith(".jpg")) return true;
    if (filterType === "png" && file.name.endsWith(".png")) return true;
    if (filterType === "url" && file.type === "link") return true;
    return false;
  });


  const openableExtensions = ['.pdf', '.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];




  // Función para ordenar archivos
  const paginatedFiles = filteredFiles.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Función para alternar selección de elementos
  const toggleSelectItem = (id) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // Función para alternar favoritos
  const toggleFavorite = (id) => {
    setFavorites((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // Menú de opciones para carpetas
  const folderMenu = (folder) => [
    { key: "openFolder", label: "Abrir", icon: <FolderOpenOutlined style={{ color: "#1f6ddb" }} /> },

    // Si tienes permiso de actualización, agrega el item de renombrar
    ...(permissionUpdate
      ? [
        {
          key: "renameFolder",
          label: "Renombrar",
          icon: <EditOutlined />,
          onClick: ({ domEvent }) => {
            domEvent.stopPropagation();
            showRenameModal(folder);
          },
        },
      ]
      : []),

    // Si tienes permiso de eliminación, agregamos el item de eliminar
    ...(permissionDelete && selectedKey !== null
      ? [
        {
          key: "deleteFolder",
          label: "Eliminar",
          icon: <DeleteOutlined style={{ color: "red" }} />,
          onClick: ({ domEvent }) => {
            domEvent.stopPropagation();
            showDeleteConfirmation(folder);
          },
        },
      ]
      : []),

  ];

  const handleDownload = async (file) => {
    try {
      const { data } = await downloadFile(file.id);
      // Crea un enlace para descargar el archivo
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.name}.${file.extension}`); // Cambia la extensión según el tipo de archivo
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error al descargar:', error);
    }
  };

  const handleOpen = (file) => {
    const ext = file.extension?.toLowerCase();
    if (openableExtensions.includes(ext)) {
      const fileUrl = getViewFileUrl(file.id); // asumes que sirve para abrir en nueva pestaña
      window.open(fileUrl, "_blank");
    } else {
      message.warning("Este tipo de archivo no se puede previsualizar. Se descargará.");
      handleDownload(file);
    }
  };


  // Menú de opciones para archivos
  const fileMenu = (file) => {
    const items = [];

    if (permissionView) {
      items.push({
        key: "1",
        icon: <FileOutlined className="menu-icon" />,
        label: "Abrir",
        onClick: () => handleOpen(file),
      });
    }

    if (permissionExport) {
      items.push({
        key: "2",
        icon: <DownloadOutlined className="menu-icon" />,
        label: "Descargar",
        onClick: () => handleDownload(file),
      });
    }

    if (permissionDelete) {
      items.push({
        key: "3",
        icon: <DeleteOutlined className="menu-icon text-danger" />,
        label: "Eliminar archivo",
        onClick: () => showDeleteFileConfirmation(file),
      });
    }

    return items;
  };


  // Función para encontrar una ruta en la estructura
  const findPathInStructure = (structure, targetPath) => {
    if (structure.path === targetPath) {
      return structure;
    }
    if (structure.children && structure.children.length > 0) {
      for (let child of structure.children) {
        let found = findPathInStructure(child, targetPath);
        if (found) return found;
      }
    }
    return undefined;
  };



  function buildBreadcrumbFor(folderItem, root) {
    // vas subiendo por parentKey
    let breadcrumb = [];
    let current = folderItem;

    while (current) {
      // en lugar de "breadcrumb.unshift(current.name)",
      // mete un objeto con { id, name }
      breadcrumb.unshift({ id: current.id, name: current.name });

      if (current.parentKey == null) {
        break;
      }
      current = findNodeById(current.parentKey, root);
    }

    return breadcrumb;
  }


  function findNodeById(nodeId, node) {
    const numericId = parseInt(nodeId, 10); // o Number(nodeId)

    if (node.id === numericId) {
      return node;
    }
    if (node.children) {
      for (const child of node.children) {
        const found = findNodeById(numericId, child);
        if (found) return found;
      }
    }
    return null;
  }

  // Función para manejar clics en el menú
  const handleClick = (folderItem) => {
    setSelectedItems([]);
    setSelectedPath(folderItem.path);

    // 1) Construimos la miga de pan, sin importar si es raíz o no
    const newBreadcrumb = buildBreadcrumbFor(folderItem, dataInicial);
    setBreadcrumbPath(newBreadcrumb);

    // 2) Ajustamos el estado para la carpeta seleccionada
    setSelectedFolder(folderItem.name);
    setSelectedKey(folderItem.id);

    // 3) Filtramos carpetas e hijos
    const arrayData = folderItem.children || [];
    const carpetasFiltradas = filtrarCarpetas(arrayData, 1);
    const archivosFiltrados = filtrarCarpetas(arrayData, 2);
    setFilteredDataFiles(archivosFiltrados);
    setFilteredFolders(carpetasFiltradas);

    // Contamos archivos/carpetas en este nivel
    setRootFileCount(arrayData.filter((child) => child.type === "file").length);
    setTotalFolderCount(arrayData.filter((child) => child.type === "folder").length);

    // 4) Abre el SubMenu en el menú lateral
    setOpenKeys([folderItem.id]);
  };

  // Función para formatear el texto
  const formatName = (name) => {
    // Excepciones específicas
    const excepciones = {
      repositorio: "Repositorio Documental",

    };

    if (excepciones[name]) {
      return excepciones[name];
    }

    return name; // Capitaliza
  };


  // Función para renderizar elementos del menú
  const renderMenuItems = (items) => {
    return items
      .filter((item) => item.type === "folder")
      .map((item, index) => (
        <SubMenu
          key={`${item.id}-${index}-${item.path}`}
          icon={<FolderOutlined />}
          title={formatName(item.name)}
          onTitleClick={() => handleClick(item)}
        >
          {renderMenuItems(item.children || [])}
        </SubMenu>
      ));
  };



  // Función para manejar cambios en la paginación
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        let data = [];
        if (proyectoCodigo) {
          const codigoLimpio = proyectoCodigo.replace(/^P0?|P\d+/, "");
          data = await RepositorioPOAProyecto.getDato(codigoLimpio);
        } else {
          data = await RepositorioService.getDocuments(userLogin);
        }
        setDataInicial({ ...initialStructure, children: data });
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDocuments();
  }, [proyectoCodigo, userLogin]);

  // Función para cerrar el Drawer
  const findPathInStructureMP = (structure, folderPath) => {
    const lastFolder = folderPath.at(-1);
    if (structure.name === lastFolder) {
      return structure;
    }
    if (structure.children && structure.children.length > 0) {
      for (let child of structure.children) {
        let found = findPathInStructureMP(child, folderPath);
        if (found) return found;
      }
    }

    return undefined;
  };

  // Función para navegar a una carpeta Migajas De Pan
  // Función para navegar en la miga de pan
  const navigateToFolder = (partialBreadcrumb, event) => {
    event.stopPropagation();

    // El último objeto del array es la carpeta a la que realmente vamos
    const lastObj = partialBreadcrumb[partialBreadcrumb.length - 1];


    // Buscar en tu árbol de dataInicial
    // (asumiendo que usas findNodeById)
    const selectedData = findNodeById(lastObj.id, dataInicial);

    if (!selectedData) {
      // Si no se encuentra, probablemente es la raíz o algo nulo
      console.warn("Carpeta no encontrada");
      return;
    }

    // Ajustar "selected" folder y Key
    setSelectedKey(selectedData.id);
    setSelectedFolder(selectedData.name);

    // Filtrar subcarpetas e hijos
    const arrayData = selectedData.children || [];
    const carpetasFiltradas = filtrarCarpetas(arrayData, 1);
    const archivosFiltrados = filtrarCarpetas(arrayData, 2);
    setFilteredDataFiles(archivosFiltrados);
    setFilteredFolders(carpetasFiltradas);

    // Contar archivos y carpetas
    setRootFileCount(arrayData.filter((i) => i.type === "file").length);
    setTotalFolderCount(arrayData.filter((i) => i.type === "folder").length);

    // Actualizar el breadcrumb actual con EXACTAMENTE esa porción
    setBreadcrumbPath(partialBreadcrumb);
  };


  const currentPathString = useMemo(() => {
    return breadcrumbPath.map(f => formatName(f.name)).join(" > ");
  }, [breadcrumbPath]);

  const pathForDeletion = useMemo(() => {
    if (!folderToDelete) return breadcrumbPath;

    const lastCrumb = breadcrumbPath[breadcrumbPath.length - 1];
    // Si el último elemento no coincide con la carpeta a eliminar, la agregamos
    if (!lastCrumb || lastCrumb.id !== folderToDelete.id) {
      return [...breadcrumbPath, { id: folderToDelete.id, name: folderToDelete.name }];
    }
    return breadcrumbPath;
  }, [breadcrumbPath, folderToDelete]);



  // Función recursiva para actualizar el árbol
  const handleNewFolder = (newFolder) => {
    // Función recursiva para insertar la carpeta en el árbol actual
    const insertFolder = (node) => {
      if (node.id === newFolder.parentKey) {
        return { ...node, children: [...(node.children || []), newFolder] };
      }
      if (node.children) {
        return { ...node, children: node.children.map(child => insertFolder(child)) };
      }
      return node;
    };

    setDataInicial(prevData => insertFolder(prevData));

    if (newFolder.parentKey === selectedKey) {
      setFilteredFolders(prevFolders => [...prevFolders, newFolder]);
      setTotalFolderCount(prevCount => prevCount + 1);
    }

  };


  const handleUploadFolder = (estructura) => {
    if (!estructura) {
      console.error("Estructura vacía");
      return;
    }

    const newBranch = Array.isArray(estructura) ? estructura[0] : estructura;

    if (!newBranch || !newBranch.id || !newBranch.parentKey) {
      console.error("Nodo recibido inválido:", newBranch);
      return;
    }
    const findNodeById = (tree, id) => {
      if (tree.id === id) return tree;
      if (!tree.children) return null;

      for (const child of tree.children) {
        const found = findNodeById(child, id);
        if (found) return found;
      }
      return null;
    };

    // 🔁 Reemplaza el nodo por ID, con todo y sus hijos
    const deepReplaceNode = (tree, newNode) => {
      if (tree.id === newNode.id) {
        return newNode; // Reemplaza todo el nodo
      }

      return {
        ...tree,
        children: tree.children?.map(child => deepReplaceNode(child, newNode)) || []
      };
    };

    // 🌱 Inserta o reemplaza en el árbol recursivamente según parentKey
    const insertOrUpdateTree = (tree, newNode) => {
      if (tree.id === newNode.parentKey) {
        const childIndex = tree.children?.findIndex(c => c.id === newNode.id) ?? -1;
        const updatedChildren = [...(tree.children || [])];

        if (childIndex >= 0) {
          updatedChildren[childIndex] = newNode; // Reemplaza por completo
        } else {
          updatedChildren.push(newNode); // Inserta nuevo
        }

        return {
          ...tree,
          children: updatedChildren
        };
      }

      return {
        ...tree,
        children: tree.children?.map(child => insertOrUpdateTree(child, newNode)) || []
      };
    };

    setDataInicial(prevData => {
      const existingNode = findNodeById(prevData, newBranch.id);
      let updatedTree;

      if (existingNode) {
        // Si ya existe, reemplázalo profundamente
        updatedTree = deepReplaceNode(prevData, newBranch);
      } else {
        // Si no existe, insértalo en su parentKey
        updatedTree = insertOrUpdateTree(prevData, newBranch);
      }

      return updatedTree;
    });

    if (newBranch.parentKey === selectedKey) {
      setFilteredFolders(prevFolders => {
        const exists = prevFolders.some(f => f.id === newBranch.id);
        if (exists) {
          // Reemplazar el nodo existente con el nuevo
          return prevFolders.map(f =>
            f.id === newBranch.id ? newBranch : f
          );
        } else {

          // Agregarlo si no existe
          return [...prevFolders, newBranch];
        }
      });

      setTotalFolderCount(prevCount => prevCount + 1);

    }
  };





  const handleFolderRenamed = (updatedFolder, oldPath) => {
    // Función recursiva para actualizar el nodo renombrado y sus hijos
    const updateFolderInTree = (node) => {
      if (node.id === updatedFolder.id) {

        const updatedNode = {
          ...node,
          name: updatedFolder.name,
          path: updatedFolder.path,
          children: node.children
            ? node.children.map(child => {
              if (child.path.startsWith(oldPath)) {
                const newChildPath = child.path.replace(oldPath, updatedFolder.path);

                return { ...child, path: newChildPath };
              }
              return child;
            })
            : []
        };

        return updatedNode;
      }
      if (node.children && node.children.length > 0) {
        return { ...node, children: node.children.map(child => updateFolderInTree(child)) };
      }
      return node;
    };

    // Actualizamos el árbol completo
    setDataInicial(prevData => {
      const newTree = updateFolderInTree(prevData);

      // Buscamos el nodo actualmente seleccionado (aunque el renombrado no sea el nodo seleccionado)
      const selectedNode = findNodeById(selectedKey, newTree);
      if (selectedNode && selectedNode.children) {
        setFilteredFolders(selectedNode.children);
      } else {
        setFilteredFolders([]);
      }

      return newTree;
    });

    // Si el nodo actualmente seleccionado es el que se renombró, actualizamos también selectedFolder y el breadcrumb
    if (selectedKey === updatedFolder.id) {
      setSelectedFolder(updatedFolder.name);
      const updatedBreadcrumb = breadcrumbPath.map(item =>
        item.id === updatedFolder.id ? { ...item, name: updatedFolder.name } : item
      );

      setBreadcrumbPath(updatedBreadcrumb);
    }
  };



  // Función para mostrar el modal
  const showDeleteConfirmation = (folder) => {
    setFolderToDelete(folder);
    setDeleteModalVisible(true);
  };

  // Función para manejar la eliminación
  const handleDeleteFolder = async () => {
    try {
      setLoading(true);
      const response = await deleteFolder({ folderId: folderToDelete.id, username: userLogin });
      message.success(response.message);
      // Actualiza el árbol eliminando la carpeta
      handleFolderDeleted(folderToDelete.id);
      setDeleteModalVisible(false);
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        switch (status) {
          case 404:
            message.error(data.message || "Carpeta no encontrada.");
            break;
          case 500:
            message.error(data.message || "Error interno del servidor. No se pudo eliminar la carpeta.");
            break;
          default:
            message.error(data.message || "No se pudo eliminar la carpeta.");
        }
      } else {
        message.error("No se pudo eliminar la carpeta.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFolderDeleted = (deletedFolderId) => {
    // Función recursiva para eliminar el nodo del árbol
    const removeFolderFromTree = (node) => {
      if (node.id === deletedFolderId) {
        return null;
      }
      if (!node.children) return node;
      const updatedChildren = node.children
        .filter(child => child.id !== deletedFolderId)
        .map(child => removeFolderFromTree(child));
      return { ...node, children: updatedChildren };
    };

    setDataInicial(prevData => removeFolderFromTree(prevData));

    // Si la carpeta eliminada estaba en la vista actual (filteredFolders),
    // actualiza también ese estado
    setFilteredFolders(prevFolders =>
      prevFolders.filter(folder => folder.id !== deletedFolderId)
    );
  };


  const showRenameModal = (folder) => {
    setFolderToRename(folder);
    setRenameModalVisible(true);
  };


  // Subcomponente o función que muestra la “Ruta actual”
  const RutaActual = ({ currentPath }) => {
    return (
      <div
        style={{
          backgroundColor: "#fafafa",
          padding: "16px",
          borderRadius: "6px",
          marginBottom: "16px",
          border: "1px solid #d9d9d9",
        }}
      >
        <Divider orientation="left" orientationMargin={0}>
          <Space>
            <FolderOpenOutlined style={{ color: "#1677ff", fontSize: 16 }} />
            <Text strong style={{ margin: 0 }}>
              Ruta actual
            </Text>
          </Space>
        </Divider>
        <div className="d-flex align-items-center">
          {currentPath.map((folderObj, index) => (
            <React.Fragment key={folderObj.id}>
              {index > 0 && (
                <RightOutlined style={{ fontSize: 12, color: "#999", margin: "0 4px" }} />
              )}
              <Text strong>{formatName(folderObj.name)}</Text>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  // Mostrar modal
  const showDeleteFileConfirmation = (file) => {
    setFileToDelete(file);
    setDeleteFileModalVisible(true);
  };


  const handleFileUploaded = (newFile) => {
    // 1. Actualiza los archivos filtrados si el archivo pertenece a la carpeta actual
    if (parseInt(newFile.parentKey) === selectedKey) {
      setFilteredDataFiles(prevFiles => [...prevFiles, newFile]);

      setRootFileCount(prev => prev + 1);
    }

    // 2. Inserta el archivo en el árbol principal
    const insertFileIntoTree = (node) => {
      if (node.id === parseInt(newFile.parentKey)) {
        return {
          ...node,
          children: [...(node.children || []), newFile]
        };
      }
      if (node.children) {
        return {
          ...node,
          children: node.children.map(child => insertFileIntoTree(child))
        };
      }
      return node;
    };

    setDataInicial(prevData => insertFileIntoTree(prevData));
  };

  // Eliminar archivo
  const handleDeleteFile = async () => {
    try {
      setLoading(true);
      const response = await deleteFile({ fileId: fileToDelete.id, username: userLogin });
      message.success(response.message);
      handleFileDeleted(fileToDelete.id);
      setDeleteFileModalVisible(false);
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        switch (status) {
          case 404:
            message.error(data.message || "Archivo no encontrado.");
            break;
          case 500:
            message.error(data.message || "Error interno del servidor.");
            break;
          default:
            message.error(data.message || "No se pudo eliminar el archivo.");
        }
      } else {
        message.error("No se pudo eliminar el archivo.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Actualizar estado
  const handleFileDeleted = (deletedFileId) => {
    // Elimina del árbol completo
    const removeFileFromTree = (node) => {
      if (!node.children) return node;
      const updatedChildren = node.children
        .filter((child) => child.id !== deletedFileId)
        .map((child) => removeFileFromTree(child));
      return { ...node, children: updatedChildren };
    };

    setDataInicial((prevData) => removeFileFromTree(prevData));

    // Elimina de la lista visible
    setFilteredDataFiles((prev) =>
      prev.filter((file) => file.id !== deletedFileId)
    );
  };


  //Multiples archivos
  const showDeleteMultipleFilesConfirmation = () => {
    Modal.confirm({
      title: "Eliminar archivos",
      content: `¿Estás segur@ de eliminar los ${selectedItems.length} archivos seleccionados?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk: () => handleDeleteMultipleFiles(selectedItems),
    });
  };

  const handleDeleteMultipleFiles = async (fileIds) => {
    try {
      setLoading(true);

      const responses = await Promise.all(
        fileIds.map((id) =>
          deleteFile({ fileId: id, username: userLogin }).catch((err) => ({ error: err }))
        )
      );

      // Filtra los exitosos
      const successCount = responses.filter((res) => !res?.error).length;
      message.success(`${successCount} archivo(s) eliminados correctamente.`);

      // Actualiza el estado eliminando los archivos del UI
      fileIds.forEach((id) => handleFileDeleted(id));
      setSelectedItems([]); // Limpiar selección

    } catch (err) {
      message.error("Error al eliminar archivos.");
    } finally {
      setLoading(false);
    }
  };

  const findFileInTree = (node, fileId) => {
    if (node.type === "file" && node.id === fileId) {
      return node;
    }

    if (node.children) {
      for (const child of node.children) {
        const result = findFileInTree(child, fileId);
        if (result) return result;
      }
    }

    return null;
  };

  const handleDownloadZip = async () => {
    try {
      const response = await downloadZip(selectedItems);
      const blob = new Blob([response.data], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'archivos.zip');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      message.error("Error al descargar archivos");
      console.error(err);
    }
  };


  useEffect(() => {
    if (dataInicial?.children) {
      updateStats(dataInicial.children);
    }
  }, [dataInicial, updateStats]);

  const toggleLista = () => {
    setMostrarLista(prev => !prev);
  };

  useEffect(() => {
    if (selectedKey !== 1 && selectedKey !== null) {
      setMostrarLista(false); // ✅ Esta es la función que modifica el estado
    }
  }, [selectedKey]);

  //Ordenar de menor a mayor las carpetas
  const sortedFolders = [...filteredFolders].sort((b, a) => {
    const numA = parseInt(a.name.match(/^P(\d+)/)?.[1] || 0, 10);
    const numB = parseInt(b.name.match(/^P(\d+)/)?.[1] || 0, 10);
    return numB - numA;
  });

  const applyFilters = () => {
    let filteredData = [...filteredDataFiles]; // Copia de los datos originales

    // Filtrar por término de búsqueda (si existe)
    if (searchTerm) {
      filteredData = filteredData.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Filtrar por tipo de archivo (mejorado para manejar extensiones)
    if (filterType !== 'all') {
      filteredData = filteredData.filter(item => {
        // Extraer la extensión del nombre del archivo
        const fileExt = item.name.split('.').pop().toLowerCase();

        // Mapeo de tipos a extensiones
        const typeMap = {
          'pdf': ['pdf'],
          'docx': ['docx', 'doc'],
          'xlsx': ['xlsx', 'xls'],
          'jpg': ['jpg', 'jpeg'],
          'png': ['png'],
          'url': ['url', 'http', 'https'] // Para enlaces
        };

        return typeMap[filterType]?.includes(fileExt) || false;
      });
    }

    // Ordenar los datos (mejorado para números primero)
    filteredData.sort((a, b) => {
      if (sortBy === 'name') {
        // Función para extraer partes numéricas y alfabéticas
        const getSortableValue = (str) => {
          // Extraer números del principio del string
          const numMatch = str.match(/^\d+/);
          const numPart = numMatch ? parseInt(numMatch[0], 10) : null;
          const alphaPart = numMatch ? str.slice(numMatch[0].length) : str;

          return {
            num: numPart !== null ? numPart : Infinity, // Poner números primero
            alpha: alphaPart.toLowerCase()
          };
        };

        const aVal = getSortableValue(a.name);
        const bVal = getSortableValue(b.name);

        // Primero comparar partes numéricas
        if (aVal.num !== bVal.num) {
          return sortOrder === 'asc'
            ? aVal.num - bVal.num
            : bVal.num - aVal.num;
        }

        // Si las partes numéricas son iguales, comparar alfabéticamente
        return sortOrder === 'asc'
          ? aVal.alpha.localeCompare(bVal.alpha)
          : bVal.alpha.localeCompare(aVal.alpha);
      } else if (sortBy === 'date') {
        const parseCustomDate = (dateStr) => {
          if (!dateStr) return new Date(0);
          const parts = dateStr.split('-');
          if (parts.length === 3) {
            return new Date(parts[2], parts[1] - 1, parts[0]);
          }
          return new Date(dateStr);
        };
        const dateA = parseCustomDate(a.createdAt);
        const dateB = parseCustomDate(b.createdAt);
        return sortOrder === 'asc'
          ? dateA - dateB
          : dateB - dateA;
      } else if (sortBy === 'size') {
        return sortOrder === 'asc'
          ? a.size - b.size
          : b.size - a.size;
      }
      return 0;
    });

    return filteredData;
  };

  // Obtener datos filtrados
  const filteredData = applyFilters();

  const onSearch = (value) => {
    setSearchTerm(value);
  };

  const handleShowPlan = (file) => {
    setSelectedFilePalnPedagogico(file); // Asumiendo que planesPP contiene tu JSON
    setModalVisiblePalnPedagogico(true);
  };

  return (
    <Drawer
      className="custom-drawer-repositorio"
      placement="right"
      size="large"
      onClose={onClose}
      open={open}
      closable={false}
      width={"100%"}
      title={
        <Row
          align="middle"
          justify="space-between"
          style={{ background: "#1677ff", width: "100%", flexWrap: "nowrap", height: "50px" }}
        >
          {/* Título */}
          <Col>
            <Space align="center">
              <Button
                size="small"
                type="text"
                icon={menuVisible ? <LeftOutlined /> : <RightOutlined />}
                onClick={() => setMenuVisible(!menuVisible)}
              />
              <h3 style={{ background: "#1677ff", margin: 0, color: '#FFF' }}>Repositorio documental</h3>
            </Space>
          </Col>


          {/* Barra de búsqueda y botones */}
          <Col flex="auto">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "12px",
                flexWrap: "wrap",
                verticalAlign: "middle",
              }}
            >

              <Search
                placeholder="Buscar archivo..."
                onSearch={onSearch}
                onChange={(e) => onSearch(e.target.value)}
                style={{ maxWidth: 300 }}
                enterButton={
                  <Button
                    type="text"
                    icon={<SearchOutlined style={{ color: '#1890ff' }} />}
                    style={{
                      backgroundColor: '#1677ff',
                      border: '1px solid #ccc',
                      padding: '0 12px',
                    }}
                  />
                }
                allowClear // Permite limpiar la búsqueda fácilmente
              />


              {(selectedKey === 1 || selectedKey === null) && (
                <Button onClick={toggleLista}>
                  <OrderedListOutlined />
                  {mostrarLista ? 'Ocultar lista' : 'Mostrar lista'}
                </Button>

              )}

              < Dropdown menu={filterMenu} trigger={["click"]}>
                <Button icon={<FilterOutlined />}>Filtrar</Button>
              </Dropdown>
              <Dropdown menu={{ items }} trigger={["click"]}>
                <Button icon={<AppstoreOutlined />}>Vista</Button>
              </Dropdown>


              {permissionInsert && (

                <CrearCarpeta
                  parentId={selectedKey}
                  diseno={1}
                  currentPath={currentPathString}
                  username={userLogin}
                  onFolderCreated={handleNewFolder}
                  onFolderUploaded={handleUploadFolder}
                  onFileUploaded={handleFileUploaded}
                  pathActual={selectedPath}
                />

              )}



              <Button type="primary" icon={<CloseOutlined />} onClick={onClose} style={{ background: "#FFF", color: "#000" }}></Button>


            </div>
          </Col>
        </Row >
      }

    >
      {/* Contenedor principal del Drawer */}
      < div className="container-repositorio" >
        {/* Menú lateral con pestañas y estadísticas */}
        {menuVisible && (

          <div className="menu-repositorio">
            <div className="botones-tab-repositorio">
              {/* Pestañas de navegación */}
              <div className="border-bottom">
                <Tabs
                  defaultActiveKey="all"
                  onChange={setActiveTab}
                  centered
                  items={[
                    { key: "all", label: "Todos" },
                    { key: "favorites", label: "Favoritos" },
                  ]}
                />

              </div>

              {/* Estadísticas del repositorio */}
              {activeTab === "all" && (

                <div className="p-3 border-bottom bg-light">
                  <div className="small text-muted">
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <Text>
                          <FileOutlined style={{ marginRight: 8, color: "#1677ff" }} />
                          Total archivos:
                        </Text>
                        <Text strong>{stats.totalFiles.toLocaleString()}</Text>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <Text>
                          <FolderOpenOutlined style={{ marginRight: 8, color: "#52c41a" }} />
                          Total carpetas:
                        </Text>
                        <Text strong>{stats.totalFolders.toLocaleString()}</Text>
                      </div>
                    </Space>
                  </div>
                </div>
              )}

              {/* Contenido de las pestañas */}
              <div style={{ height: "calc(100vh - 200px)", overflowY: "auto" }}>
                {isLoading ? (
                  <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <Spin size="large" />
                    <Text type="secondary">Cargando carpetas...</Text>

                  </div>
                ) : (
                  <div>
                    {activeTab === "all" && (
                      <div style={{ height: "calc(100vh - 200px)", overflowY: "auto" }}>
                        {isLoading ? (
                          <div className="d-flex flex-column align-items-center justify-content-center h-100">
                            <Spin size="large" />
                            <Text type="secondary">Cargando carpetas...</Text>
                          </div>
                        ) : (
                          <Menu mode="inline" selectedKeys={[selectedKey]} openKeys={openKeys} onOpenChange={setOpenKeys}>
                            {renderMenuItems(dataInicial.children || [])}
                          </Menu>
                        )}
                      </div>
                    )}
                    {activeTab === "recent" && (
                      <div className="p-2">
                        <Title level={5} type="secondary">
                          Accedidos recientemente
                        </Title>
                        {Array.from({ length: 5 }).map((_, i) => (
                          <div
                            key={i}
                            className="d-flex align-items-center p-2 hover-bg-light rounded"
                          >
                            <ClockCircleOutlined className="me-2 text-muted" />
                            <Text className="small">
                              Documento accedido recientemente {i + 1}
                            </Text>
                          </div>
                        ))}
                        <Title level={5} type="secondary" className="mt-4">
                          Modificados recientemente
                        </Title>
                        {Array.from({ length: 5 }).map((_, i) => (
                          <div
                            key={i}
                            className="d-flex align-items-center p-2 hover-bg-light rounded"
                          >
                            <HistoryOutlined className="me-2 text-muted" />
                            <Text className="small">
                              Archivo modificado recientemente {i + 1}
                            </Text>
                          </div>
                        ))}
                      </div>
                    )}
                    {activeTab === "favorites" && (
                      <div className="p-2">
                        <Title level={5} type="secondary">
                          Elementos favoritos
                        </Title>
                        {favorites.length > 0 ? (
                          favorites.map((id) => {
                            const file = findFileInTree(dataInicial, id);
                            if (!file) return null;

                            return (
                              <div
                                key={id}
                                className="d-flex align-items-center p-2 hover-bg-light rounded"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleOpen(file)} // ✅ usamos tu función directamente
                              >
                                <StarFilled className="me-2 text-warning" />
                                <Tooltip title="Abrir archivo">
                                  <Text className="small" style={{ color: "#1677ff" }}>
                                    {file.name}
                                  </Text>
                                </Tooltip>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            className="text-center d-flex flex-column align-items-center justify-content-center"
                            style={{ minHeight: "200px" }}
                          >
                            <HeartOutlined
                              style={{
                                fontSize: "50px",
                                opacity: 0.2,
                                color: "#dddddd",
                              }}
                              className="mb-2"
                            />
                            <Text className="small" style={{ color: "#8C8C8C" }}>
                              No hay elementos favoritos
                            </Text>
                            <Text
                              className="small mt-1"
                              style={{ color: "#8C8C8C" }}
                            >
                              Marca archivos como favoritos para acceder
                              rápidamente
                            </Text>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
        }
        {/* Contenido principal del repositorio */}
        <div className="content-repositorio">

          <div>
            {/* Título de la carpeta actual */}
            <div className="mb-3 mt-2">
              <Typography.Title
                level={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <FolderOpenOutlined
                  style={{ fontSize: "20px", marginRight: "5px", color: "#0d6efd" }}
                />
                <strong>{formatName(selectedFolder)}</strong>
              </Typography.Title>
              <div style={{ fontSize: "15px", color: "#666" }}>
                {rootFileCount} elemento{rootFileCount !== 1 ? "s" : ""} •{" "}
                {totalFolderCount} subcarpeta{totalFolderCount !== 1 ? "s" : ""}

              </div>

              {/* Breadcrumb para navegación */}
              <Col style={{ flex: 1, minWidth: 0 }}>
                <div className="d-flex align-items-center overflow-auto pb-2 pb-md-0">
                  <strong>Ubicación: </strong>
                  {breadcrumbPath.map((folderObj, index) => (
                    <div key={folderObj.id} className="d-flex align-items-center">
                      {index > 0 && (
                        <RightOutlined
                          className="mx-0 text-muted"
                          style={{ fontSize: "12px" }}
                        />
                      )}

                      <Button
                        type="link"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={(e) =>
                          // "breadcrumbPath.slice(0, index + 1)" es el array parcial de objetos
                          navigateToFolder(breadcrumbPath.slice(0, index + 1), e)
                        }
                      >

                        <strong>{formatName(folderObj.name)}</strong>
                      </Button>
                    </div>
                  ))}
                </div>
              </Col>

              {(selectedKey === 1 || selectedKey === null) && mostrarLista && (

                <Col style={{ flex: 1, minWidth: 0 }}>
                  <div className="d-flex justify-content-center w-100 overflow-auto pb-2 pb-md-0">
                    <ListaRepositorio />
                  </div>
                </Col>
              )}


            </div>

            <div style={{ fontSize: "14px", color: "#666" }}>
              {sortedFolders.length === 0 && filteredDataFiles.length === 0 ? (
                <>
                  <Divider style={{ margin: "20px 0" }} />
                  <div className="text-center py-2">
                    <FolderOpenOutlined style={{ fontSize: "3rem" }} className="text-muted mb-3" />
                    <h5>No hay carpetas</h5>
                    <p className="text-muted mb-4">
                      {searchQuery
                        ? "No se encontraron archivos que coincidan con tu búsqueda."
                        : "Esta carpeta está vacía."}
                    </p>



                    {permissionInsert && (

                      <CrearCarpeta
                        parentId={selectedKey}
                        diseno={2}
                        currentPath={currentPathString}
                        username={userLogin}
                        onFolderCreated={handleNewFolder}
                        onFolderUploaded={handleUploadFolder}
                        onFileUploaded={handleFileUploaded}
                        pathActual={selectedPath}
                      />

                    )}


                    {searchQuery && (
                      <Button type="primary" onClick={() => setSearchQuery("")}>
                        Limpiar búsqueda
                      </Button>
                    )}
                  </div>
                </>
              ) : (


                <>
                  {sortedFolders.length > 0 && (
                    <>


                      <Divider style={{ borderColor: '#1a1a1a', borderWidth: 2 }} orientation="left">
                        <strong>Carpetas</strong>
                      </Divider>

                      {/* Vista de LISTA para carpetas */}
                      {viewMode === "list" && (
                        <div className="d-flex flex-column gap-2">
                          {sortedFolders.map((folder) => {
                            const fileCount = folder.children?.length ?? 0;
                            return (
                              <Card
                                key={folder.id}
                                hoverable
                                className="mb-0"
                                style={{ padding: "12px" }}
                                onMouseEnter={() => setHoveredFolder(folder.id)}
                                onMouseLeave={() => setHoveredFolder(null)}
                                onClick={() => handleClick(folder)}
                              >
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center flex-grow-1 min-width-0">
                                    <FolderOutlined className="me-3 fs-5 text-primary" />
                                    <div className="min-width-0">
                                      <Typography.Text strong className="d-block text-truncate">
                                        {formatName(folder.name)}
                                      </Typography.Text>
                                      <Typography.Text type="secondary" className="small">
                                        {fileCount} elemento{fileCount !== 1 ? "s" : ""}
                                      </Typography.Text>
                                    </div>
                                  </div>
                                  {/* Botón extra cuando hover */}


                                  <Dropdown menu={{ items: folderMenu(folder) }} trigger={["click"]}>
                                    <Button
                                      type="primary"
                                      shape="circle"
                                      icon={<EllipsisOutlined />}
                                      onClick={(e) => e.stopPropagation()}
                                      style={{
                                        backgroundColor: "#1677ff",  // tu color preferido
                                        borderColor: "#1677ff"       // mismo color para el borde
                                      }}
                                    />
                                  </Dropdown>


                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      )}

                      {/* Vista de CUADRÍCULA (grid) para carpetas */}
                      {viewMode === "grid" && (
                        <Row gutter={[16, 16]} className="g-3">

                          {sortedFolders.map((folder) => {
                            const fileCount = folder.children?.length ?? 0;
                            return (
                              <Col key={folder.id} xs={6} sm={6} md={6} lg={6}>
                                <Card
                                  hoverable
                                  className="text-center position-relative"
                                  style={{ padding: "15px" }}
                                  onMouseEnter={() => setHoveredFolder(folder.id)}
                                  onMouseLeave={() => setHoveredFolder(null)}
                                  onClick={() => handleClick(folder)}
                                >
                                  <FolderOutlined
                                    style={{ fontSize: "2.5rem" }}
                                    className="text-primary mb-2"
                                  />
                                  <Typography.Text strong className="d-block text-truncate">
                                    {formatName(folder.name)}
                                  </Typography.Text>
                                  <Typography.Text type="secondary" className="small">
                                    {fileCount} elemento{fileCount !== 1 ? "s" : ""}
                                  </Typography.Text>

                                  <Dropdown menu={{ items: folderMenu(folder) }} trigger={["click"]}>
                                    <Button
                                      type="text"
                                      shape="circle"
                                      icon={<EllipsisOutlined />}
                                      className="position-absolute top-0 end-0 m-1"
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  </Dropdown>

                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      )}

                      {/* Vista COMPACTA para carpetas */}
                      {viewMode === "compact" && (
                        <div className="border rounded overflow-auto">
                          <table className="table table-hover mb-0 table-responsive-name">
                            <thead className="bg-light">
                              <tr>
                                <th>Nombre</th>
                                <th style={{ textAlign: "center" }}>Recursos</th>
                                <th style={{ textAlign: "center", width: "80px" }}>Acciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              {sortedFolders.map((folder) => {
                                const fileCount = folder.children?.length ?? 0;
                                return (
                                  <tr
                                    key={folder.id}
                                    onMouseEnter={() => setHoveredFolder(folder.id)}
                                    onMouseLeave={() => setHoveredFolder(null)}
                                    onClick={() => handleClick(folder)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td>
                                      <FolderOutlined className="me-2 fs-5 text-primary" />

                                      {formatName(folder.name)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>{fileCount}</td>
                                    <td style={{ textAlign: "center" }}>
                                      <Dropdown menu={{ items: folderMenu(folder) }} trigger={["click"]}>
                                        <Button
                                          type="primary"
                                          shape="circle"
                                          icon={<EllipsisOutlined />}
                                          onClick={(e) => e.stopPropagation()}
                                          style={{
                                            backgroundColor: "#1677ff",  // tu color preferido
                                            borderColor: "#1677ff"       // mismo color para el borde
                                          }}
                                        />
                                      </Dropdown>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  )}

                </>
              )}
            </div>
            <Divider style={{ margin: "15px 0" }} />
            {filteredData.length > 0 ? (
              <>
                <Divider style={{ borderColor: '#1a1a1a', borderWidth: 2 }} orientation="left">
                  <strong>Archivos</strong>
                </Divider>
                <Row className="mb-3" align="middle">
                  {/* Columna 1: Título, alineada a la izquierda */}
                  <Col xs={24} md={6} style={{ textAlign: "left" }}>
                  </Col>
                  {/* Columna 2: centrada (por ej. botones de items seleccionados) */}
                  <Col xs={24} md={12} style={{ textAlign: "center" }}>
                    {selectedItems.length > 0 && (
                      <div
                        style={{
                          backgroundColor: "#dee2e64d",
                          border: "0px solid #ccc",
                          padding: "8px",
                          borderRadius: "10px",

                        }}
                      >
                        <Tooltip title="Elementos seleccionados">
                          <Button color="primary" style={{ background: "#1677ff", marginRight: 8, color: "#fff" }}>
                            {selectedItems.length}
                          </Button>
                        </Tooltip>
                        <Button onClick={() => setSelectedItems([])} style={{ marginRight: 8 }}>
                          Deseleccionar todo
                        </Button>
                        {permissionExport && (
                          <Button
                            onClick={handleDownloadZip}
                            style={{ marginRight: 8 }}
                            icon={<DownloadOutlined />}
                          >
                            Descargar ZIP
                          </Button>
                        )}

                        {permissionDelete && (
                          <Button
                            danger
                            onClick={showDeleteMultipleFilesConfirmation}
                            icon={<DeleteOutlined />}
                          >
                            Eliminar
                          </Button>
                        )}

                      </div>
                    )}
                  </Col>
                  {/* Columna 3: alineada a la derecha (iconos de “vista” list/grid/compact) */}
                  <Col xs={24} md={6} style={{ textAlign: "right" }}>
                    <div style={{ display: "inline-flex", gap: "8px" }}>
                      <Tooltip title="Vista de lista">
                        <Button
                          type="text"
                          shape="default"
                          icon={
                            <UnorderedListOutlined style={{ fontSize: 20, fontWeight: "bold" }} />
                          }
                          onClick={() => setViewMode("list")}
                          style={{
                            backgroundColor: viewMode === "list" ? "#1677ff" : "transparent",
                            color: viewMode === "list" ? "#fff" : "inherit",
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Vista de cuadrícula">
                        <Button
                          type="text"
                          shape="default"
                          icon={
                            <HolderOutlined style={{ fontSize: 20, fontWeight: "bold" }} />
                          }
                          onClick={() => setViewMode("grid")}
                          style={{
                            backgroundColor: viewMode === "grid" ? "#1677ff" : "transparent",
                            color: viewMode === "grid" ? "#fff" : "inherit",
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Vista compacta">
                        <Button
                          type="text"
                          shape="default"
                          icon={
                            <FileTextOutlined style={{ fontSize: 20, fontWeight: "bold" }} />
                          }
                          onClick={() => setViewMode("compact")}
                          style={{
                            backgroundColor: viewMode === "compact" ? "#1677ff" : "transparent",
                            color: viewMode === "compact" ? "#fff" : "inherit",
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
                {viewMode === "list" && (
                  <div className="d-flex flex-column gap-2">
                    {filteredData.map((file) => (
                      <Card
                        key={file.id}
                        hoverable
                        className="mb-0"
                        style={{ padding: "12px" }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center flex-grow-1 min-width-0">
                            {!file.planesPP && (
                              <Checkbox
                                className="me-2"
                                checked={selectedItems.includes(file.id)}
                                onChange={() => toggleSelectItem(file.id)}
                                onClick={(e) => e.stopPropagation()}
                              />)}
                            {file.type === "file" ? (

                              <>
                                {getFileIconByExtension(file.extension)}
                              </>
                            ) : (
                              <GlobalOutlined className="me-3 fs-5 text-success" />
                            )}
                            <div className="min-width-0">

                              <Typography.Text strong className="d-block text-truncate">
                                <span
                                  onClick={() => handleOpen(file)}
                                  style={{
                                    cursor: 'pointer',
                                    color: '#1677ff',
                                    transition: 'color 0.2s',
                                  }}
                                  onMouseEnter={(e) => (e.target.style.color = '#0958d9')}
                                  onMouseLeave={(e) => (e.target.style.color = '#1677ff')}
                                >
                                  {file.name}
                                </span>
                              </Typography.Text>
                              <Typography.Text type="secondary" className="small">
                                {file.type === "file" ? file.size : "URL externa"} • {file.createdAt} • {file.user}
                              </Typography.Text>
                            </div>
                          </div>
                          <div className="d-flex align-items-center ms-2">
                            {file.planesPP && (
                              <Tooltip title="Plan Pedagógico">
                                <Button
                                  type="text"
                                  shape="circle"
                                  icon={<SettingOutlined />}
                                  onClick={() => handleShowPlan(file)}
                                />
                              </Tooltip>
                            )}
                          </div>
                          {!file.planesPP && (
                            <div className="d-flex align-items-center ms-2">
                              <Button
                                type="text"
                                shape="circle"
                                icon={
                                  favorites.includes(file.id) ? (
                                    <StarFilled className="text-warning" />
                                  ) : (
                                    <StarOutlined />
                                  )
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleFavorite(file.id);
                                }}
                              />
                              {/* Mostrar el Dropdown solo cuando el mouse está sobre la tarjeta */}
                              <Dropdown menu={{ items: fileMenu(file) }} trigger={["click"]}>
                                <Button type="text" shape="circle" icon={<MoreOutlined />} />
                              </Dropdown>
                            </div>)}
                        </div>
                      </Card>
                    ))}
                  </div>
                )}
                {/* Vista de cuadrícula */}
                {viewMode === "grid" && (
                  <Row gutter={[16, 16]} className="g-3"> {/* Agregamos espacio horizontal y vertical */}
                    {filteredDataFiles.map((file) => (
                      <Col key={file.id} xs={12} sm={12} md={12} lg={5} style={{ marginBottom: "16px" }}>
                        <Card
                          hoverable
                          className="text-center position-relative"
                          style={{ padding: "15px" }}
                          onClick={() => handleOpen(file)}
                        >
                          {/* Checkbox en la esquina superior izquierda */}

                          {/* Botón de favorito en la esquina superior derecha */}
                          {file.planesPP && (
                            <Tooltip title="Plan Pedagógico">
                              <Button
                                type="text"
                                shape="circle"
                                icon={<SettingOutlined />}
                                className="position-absolute top-0 end-0 m-1"
                                onClick={() => handleShowPlan(file)}
                              />
                            </Tooltip>
                          )}
                          {!file.planesPP && (
                            <Checkbox
                              className="position-absolute top-0 start-0 m-1"
                              checked={selectedItems.includes(file.id)}
                              onChange={() => toggleSelectItem(file.id)}
                              onClick={(e) => e.stopPropagation()}
                            />,
                            <Button
                              type="text"
                              shape="circle"
                              icon={
                                favorites.includes(file.id) ? (
                                  <StarFilled className="text-warning" />
                                ) : (
                                  <StarOutlined />
                                )
                              }
                              className="position-absolute top-0 end-0 m-1"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleFavorite(file.id);
                              }}
                            />)}

                          {/* Contenido central */}
                          <div className="py-2">
                            {file.type === "file" ? (
                              <>
                                {getFileIconByExtension(file.extension)}
                              </>
                            ) : (
                              <GlobalOutlined style={{ fontSize: "2.5rem" }} className="text-success mb-2" />
                            )}
                            {/* Tooltip para mostrar el nombre completo si es muy largo */}
                            <Tooltip title={file.name}>
                              <Typography.Text strong className="d-block text-truncate">
                                {file.name}
                              </Typography.Text>
                            </Tooltip>
                            <Typography.Text type="secondary" className="small">
                              {file.type === "file" ? file.size : "URL externa"} • {file.createdAt}
                            </Typography.Text>
                            <Typography.Text type="secondary" className="d-block text-truncate">
                              {file.user}
                            </Typography.Text>
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                )}
                {/* Vista compacta */}
                {viewMode === "compact" && (
                  <div className="border rounded overflow-auto">
                    <table className="table table-hover mb-0 table-responsive-name">
                      <thead className="bg-light">
                        <tr>
                          <th style={{ width: "40px" }}></th>
                          <th>Nombre</th>
                          <th style={{ width: "100px", textAlign: "center" }}>Tipo</th>
                          <th style={{ width: "100px", textAlign: "center" }}>Tamaño</th>
                          <th style={{ width: "150px" }}>Usuario</th>
                          <th style={{ width: "150px", textAlign: "center" }}>Fecha</th>
                          <th style={{ width: "80px" }}>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredDataFiles.map((file) => (
                          <tr key={file.id}>
                            <td> {!file.planesPP && (
                              <Checkbox
                                checked={selectedItems.includes(file.id)}
                                onChange={() => toggleSelectItem(file.id)}
                                onClick={(e) => e.stopPropagation()}
                              />)}
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {file.type === "file" ? (
                                  <>
                                    {getFileIconByExtension(file.extension)}
                                  </>
                                ) : (
                                  <GlobalOutlined className="me-2 icon-global" />
                                )}
                                <Tooltip title={file.name}>
                                  <span
                                    onClick={() => handleOpen(file)}
                                    style={{
                                      cursor: 'pointer',
                                      color: '#1677ff',
                                      transition: 'color 0.2s',
                                    }}
                                    onMouseEnter={(e) => (e.target.style.color = '#0958d9')}
                                    onMouseLeave={(e) => (e.target.style.color = '#1677ff')}
                                  >

                                    {file.name}</span>
                                </Tooltip>
                              </div>
                            </td>
                            <td style={{ textAlign: "center" }}>{file.name.split(".").pop()?.toUpperCase() || "URL"}</td>
                            <td style={{ textAlign: "center" }}>{file.type === "file" ? file.size : "-"}</td>
                            <td style={{ textAlign: "left" }}>{file.user}</td>
                            <td style={{ textAlign: "center" }}>{file.createdAt}</td>
                            <td>
                              {!file.planesPP && (
                                <div className="d-flex justify-content-center">
                                  <Tooltip title="Marcar como favorito">
                                    <Button
                                      type="text"
                                      size="small"
                                      icon={favorites.includes(file.id) ? <StarFilled className="icon-favorite" style={{ fontSize: 20 }} /> : <StarOutlined style={{ fontSize: 17 }} />}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleFavorite(file.id);
                                      }}
                                    />
                                  </Tooltip>
                                  {permissionExport && (
                                    <Tooltip title="Descargar archivo">
                                      <Button
                                        type="text"
                                        size="small"
                                        icon={<DownloadOutlined className="icon-download" style={{ fontSize: 17 }} />}
                                        onClick={() => handleDownload(file)}  // Asegúrate de agregar esta línea si falta
                                      />
                                    </Tooltip>
                                  )}

                                  {permissionDelete && (
                                    <Tooltip title="Eliminar archivo">
                                      <Button
                                        type="text"
                                        size="small"
                                        icon={<DeleteOutlined className="icon-delete" style={{ fontSize: 17 }} />}
                                        onClick={() => showDeleteFileConfirmation(file)}
                                      />
                                    </Tooltip>
                                  )}

                                </div>)}
                              {file.planesPP && (
                                <div className="d-flex justify-content-center">
                                  <Tooltip title="Plan Pedagógico">
                                    <Button
                                      type="text"
                                      size="small"
                                      icon={<SettingOutlined className="icon-download" style={{ fontSize: 17 }} />}
                                      onClick={() => handleShowPlan(file)}  // Asegúrate de agregar esta línea si falta
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {/* Paginación */}
                {totalPages > 1 && (
                  <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mt-4">
                    <div className="text-muted small mb-3 mb-sm-0">
                      Mostrando {(currentPage - 1) * itemsPerPage + 1} -{" "}
                    </div>
                    <Pagination
                      current={currentPage}
                      total={filteredFiles.length}
                      pageSize={itemsPerPage}
                      onChange={(page) => setCurrentPage(page)}
                      showSizeChanger={false}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                {filteredFolders.length === 0 && (
                  <div className="text-center py-5">
                    <FileOutlined style={{ fontSize: "3rem" }} className="text-muted mb-3" />
                    <h5>No hay archivos</h5>
                    <p className="text-muted mb-4">
                      {searchQuery
                        ? "No se encontraron archivos que coincidan con tu búsqueda."
                        : "Esta carpeta está vacía, sin archivos."}
                    </p>
                    {permissionInsert && (
                      <CrearCarpeta
                        parentId={selectedKey}
                        diseno={3}
                        currentPath={currentPathString}
                        username={userLogin}
                        pathActual={selectedPath}
                        onFolderCreated={handleNewFolder}
                        onFolderUploaded={handleUploadFolder}
                        onFileUploaded={handleFileUploaded}
                      />
                    )}
                    {searchQuery && (
                      <Button type="primary" onClick={() => setSearchQuery("")}>
                        Limpiar búsqueda
                      </Button>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div >
      {renameModalVisible && folderToRename && (
        <RenombrarCarpeta
          visible={renameModalVisible}
          folder={folderToRename}
          onFolderRenamed={handleFolderRenamed}
          username={userLogin}
          onClose={() => setRenameModalVisible(false)}
        />
      )}
      {
        deleteModalVisible && permissionDelete && (
          <Modal
            open={deleteModalVisible}
            title="Eliminar carpeta"
            onCancel={() => setDeleteModalVisible(false)}
            footer={null}
          >
            <Spin spinning={loading} tip="Eliminando carpeta...">
              <p>¿Estás segur@ de eliminar esta carpeta? <br />Se eliminará de forma recursiva.</p>
              <div style={{ textAlign: "right", marginTop: 16 }}>
                <Button onClick={() => setDeleteModalVisible(false)} style={{ marginRight: 8 }}>
                  Cancelar
                </Button>
                <Button type="primary" danger onClick={handleDeleteFolder}>
                  Eliminar
                </Button>
              </div>
              <Divider />
              <RutaActual currentPath={pathForDeletion} />
            </Spin>
          </Modal>)
      }
      {
        deleteFileModalVisible && permissionDelete && (
          <Modal
            open={deleteFileModalVisible}
            title="Eliminar archivo"
            onCancel={() => setDeleteFileModalVisible(false)}
            footer={null}
          >
            <Spin spinning={loading} tip="Eliminando archivo...">
              <p>¿Estás segur@ de eliminar este archivo?</p>
              <div style={{ textAlign: "right", marginTop: 16 }}>
                <Button onClick={() => setDeleteFileModalVisible(false)} style={{ marginRight: 8 }}>
                  Cancelar
                </Button>
                <Button type="primary" danger onClick={handleDeleteFile}>
                  Eliminar
                </Button>
              </div>
            </Spin>
          </Modal>
        )
      }
      {selectedFilePalnPedagogico && modalVisiblePalnPedagogico && (
        <PedagogicalPlanModal
          open={modalVisiblePalnPedagogico} // ✅ aquí debe ser "open"
          onCancel={() => setModalVisiblePalnPedagogico(false)}
          data={selectedFilePalnPedagogico}
        />)}
    </Drawer >
  );
};

export default RepositorioDocumento;