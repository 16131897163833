import React, { useEffect, useState } from "react";
import { Tabs, Form, Input, Select, Row, Col, Button, message, DatePicker, InputNumber } from "antd";
import { getAllSedes, getAllRectorias, getAllNiveles, getAllUsers, updateMatriculado, createMatriculado } from '../../../../services/Producto_3/Fort_Capacidades/CursosCiclosVida/cursoFortalecimientoCVService';

import dayjs from 'dayjs';
const { Option } = Select;

const FormCaracterizacion = ({ registroData, resetSignal, onSuccess, onPendingChanges, activeTabKey, setActiveTabKey }) => {
    const [form] = Form.useForm();
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [, setIsFormChanged] = useState(false);
    const [, setHasUnsavedChanges] = useState(false);
    const [sedes, setSedes] = useState([]);
    const [rectorias, setRectorias] = useState([]);
    const [niveles, setNiveles] = useState([]);
    const [usuarios, setUsuarios] = useState([]);

    useEffect(() => {
        if (registroData) {
            const updatedData = {
                ...registroData,
                fecha_actividad: registroData.fecha_actividad ? dayjs(registroData.fecha_actividad.toString(), 'YYYY') : null,
            };
            form.setFieldsValue(updatedData);
            setIsUpdateMode(true);
        } else {
            form.resetFields();
            setIsUpdateMode(false);
        }
    }, [registroData, form]);

    const validateCurrentTab = async (tabKey) => {
        try {
            const allFields = form.getFieldsValue();
            const requiredFields = Object.keys(allFields).filter((field) => {
                return field.includes(tabKey);
            });
            await form.validateFields(requiredFields);
            return true;
        } catch (err) {
            return false;
        }
    };

    const handleTabChange = async (key) => {
        const isValid = await validateCurrentTab(activeTabKey);
        if (isValid) {
            setActiveTabKey(key);
        } else {
            message.error("Por favor, completa los campos requeridos en este paso.");
        }
    }

    const nextTab = async () => {
        const isValid = await validateCurrentTab(activeTabKey);
        if (isValid) {
            setActiveTabKey("2");
        } else {
            message.error("Por favor, completa los campos requeridos en este paso.");
        }
    };

    const prevTab = () => {
        setActiveTabKey("1");
    };

    const handleFormChange = () => {
        setIsFormChanged(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [sedes, rectorias, niveles, usuarios] = await Promise.all([
                    getAllSedes(),
                    getAllRectorias(),
                    getAllNiveles(),
                    getAllUsers()
                ]);
                setSedes(sedes);
                setRectorias(rectorias);
                setNiveles(niveles);
                setUsuarios(usuarios);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, []);

    const handleValuesChange = () => {
        setHasUnsavedChanges(true);
        onPendingChanges(true);
    };

    const tabItems = [
        {
            key: "1",
            label: "Paso 1",
            children: (
                <div>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="periodo_saradap"
                                label={<span style={{ fontWeight: 'bold' }}>Periodo SARADAP</span>}
                                rules={[{ required: true, message: 'Por favor selecciona una opción' }]}
                            >
                                <Select placeholder="Selecciona una opción">
                                    <Option value="SI">Sí</Option>
                                    <Option value="NO">No</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={<span style={{ fontWeight: 'bold' }}>Año de actividad</span>}
                                name="fecha_actividad"
                                rules={[{ required: true, message: 'Seleccione el año de la actividad' }]}
                            >
                                <DatePicker 
                                    style={{ width: '100%' }} 
                                    picker="year" 
                                    format="YYYY" 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="spriden_pidm"
                                label={<span style={{ fontWeight: 'bold' }}>Cohorte</span>}
                                rules={[{ required: true, message: 'Ingrese la cohorte' }]}
                            >
                                <Input placeholder="Ingrese la cohorte" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="id_prospecto"
                                label={<span style={{ fontWeight: 'bold' }}>Género</span>}
                                rules={[{ required: true, message: 'Ingrese el género' }]}
                            >
                                <Select placeholder="Seleccione el género">
                                    <Option value="Hombre">Hombre</Option>
                                    <Option value="Mujer">Mujer</Option>
                                    <Option value="Otro">Otro</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="identificacion"
                                label={<span style={{ fontWeight: 'bold' }}>Identificación</span>}
                                rules={[{ required: true, message: 'Ingrese la identificación' }]}
                            >
                                <Input placeholder="Ingrese la identificación" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item 
                                name="nombre_matriculado" 
                                label={<span style={{ fontWeight: 'bold' }}>Nombre Completo</span>}
                                rules={[{ required: true, message: 'Ingrese el nombre completo' }]}
                            >
                                <Input placeholder="Ingrese el nombre completo" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="telefono"
                                label={<span style={{ fontWeight: 'bold' }}>Teléfono</span>}
                                rules={[
                                    { required: true, message: 'Ingrese el teléfono' },
                                    { pattern: /^[0-9]{7,15}$/, message: 'Ingrese un número válido (7-15 dígitos)' }
                                ]}
                            >
                                <Input placeholder="Ingrese el número de teléfono" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="email"
                                label={<span style={{ fontWeight: 'bold' }}>Correo Electrónico</span>}
                                rules={[
                                    { required: true, message: 'Ingrese el correo electrónico' },
                                    { type: 'email', message: 'Ingrese un correo válido' }
                                ]}
                            >
                                <Input placeholder="Ingrese el correo electrónico" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="oid_sede"
                                label={<span style={{ fontWeight: 'bold' }}>Sede</span>}
                                rules={[{ required: true, message: 'Por favor selecciona una sede' }]}
                            >
                                <Select placeholder="Selecciona una sede" allowClear>
                                    {sedes.map(sede => (
                                        <Option key={sede.oid} value={sede.oid}>
                                            {`${sede.sede} ${sede.descripcion}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="oid_rectoria"
                                label={<span style={{ fontWeight: 'bold' }}>Rectoría</span>}
                                rules={[{ required: true, message: 'Por favor selecciona una rectoría' }]}
                            >
                                <Select placeholder="Selecciona una rectoría" allowClear>
                                    {rectorias.map(rectoria => (
                                        <Option key={rectoria.oid} value={rectoria.oid}>
                                            {`${rectoria.rectoria}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item 
                                name="oid_nivel" 
                                label={<span style={{ fontWeight: 'bold' }}>Nivel</span>}
                                rules={[{ required: true, message: 'Por favor selecciona un nivel' }]}
                            >
                                <Select placeholder="Selecciona un nivel" allowClear>
                                    {niveles.map(nivel => (
                                        <Option key={nivel.oid} value={nivel.oid}>
                                            {`${nivel.descripcion}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="num_doc"
                                label={<span style={{ fontWeight: 'bold' }}>Tipo de Documento</span>}
                                rules={[{ required: true, message: 'Seleccione el tipo de documento' }]}
                            >
                                <Select placeholder="Seleccione el tipo de documento">
                                    <Option value="Cédula de Ciudadanía">Cédula de Ciudadanía</Option>
                                    <Option value="Tarjeta de Identidad">Tarjeta de Identidad</Option>
                                    <Option value="Cédula de Extranjería">Cédula de Extranjería</Option>
                                    <Option value="Pasaporte">Pasaporte</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            ),
        },
        {
            key: "2",
            label: "Paso 2",
            children: (
                <div>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item 
                                name="snp" 
                                label={<span style={{ fontWeight: 'bold' }}>Edad</span>}
                                rules={[{ required: true, message: 'Ingrese la edad' }]}
                            >
                                <InputNumber 
                                    placeholder="Ingrese la edad" 
                                    style={{ width: '100%' }} 
                                    min={0} 
                                    max={120}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="cos_insc"
                                label={<span style={{ fontWeight: 'bold' }}>Etnia</span>}
                                rules={[{ required: true, message: 'Seleccione la etnia' }]}
                            >
                                <Select placeholder="Seleccione la etnia">
                                    <Option value="Negro(a), mulato(a), afrodescendiente, afrocolombiano(a)">
                                        Negro(a), mulato(a), afrodescendiente, afrocolombiano(a)
                                    </Option>
                                    <Option value="Indígena">Indígena</Option>
                                    <Option value="Mestizo(a)">Mestizo(a)</Option>
                                    <Option value="Blanco(a)">Blanco(a)</Option>
                                    <Option value="Raizal del archipiélago">Raizal del archipiélago</Option>
                                    <Option value="Palenquero(a) de San Basilio">Palenquero(a) de San Basilio</Option>
                                    <Option value="Gitano(a) (Rrom)">Gitano(a) (Rrom)</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="estado_insc"
                                label={<span style={{ fontWeight: 'bold' }}>Estado de inscripción</span>}
                                rules={[{ required: true, message: "Por favor selecciona una opción" }]}
                            >
                                <Select placeholder="Selecciona una opción">
                                    <Option value={1}>Decisión Elaborada</Option>
                                    <Option value={2}>No aplica</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="estado_admision"
                                label={<span style={{ fontWeight: 'bold' }}>Estado de Admisión</span>}
                                rules={[{ required: true, message: "Por favor selecciona una opción" }]}
                            >
                                <Select placeholder="Selecciona una opción">
                                    <Option value={1}>Admitido</Option>
                                    <Option value={2}>Matriculado</Option>
                                    <Option value={3}>No aplica</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="cod_tip_adm"
                                label={<span style={{ fontWeight: 'bold' }}>Municipio/Vereda</span>}
                                rules={[{ required: true, message: 'Ingrese el municipio o vereda' }]}
                            >
                                <Input placeholder="Ingrese el municipio o vereda" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="tipo_adm"
                                label={<span style={{ fontWeight: 'bold' }}>Departamento</span>}
                                rules={[{ required: true, message: 'Ingrese el departamento' }]}
                            >
                                <Input placeholder="Ingrese el departamento" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="apl_no"
                                label={<span style={{ fontWeight: 'bold' }}>Número de Aplicación</span>}
                            >
                                <InputNumber 
                                    placeholder="Ingrese el número de aplicación" 
                                    style={{ width: '100%' }} 
                                    min={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="Saradap_Majr_Code"
                                label={<span style={{ fontWeight: 'bold' }}>Código de Materia</span>}
                            >
                                <Input placeholder="Ingrese el código de la materia" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="usuario"
                                label={<span style={{ fontWeight: 'bold' }}>Usuario</span>}
                                rules={[{ required: true, message: 'Por favor selecciona un usuario' }]}
                            >
                                <Select placeholder="Selecciona un usuario" allowClear>
                                    {usuarios.map(user => (
                                        <Option key={user.oid} value={user.oid}>
                                            {`${user.usuario}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            ),
        },
    ];

    const handleCreateOrUpdate = async () => {
        try {
            const values = await form.validateFields();
            const dataInfo = {
                ...values,
                fecha_actividad: values.fecha_actividad ? values.fecha_actividad.year() : null,
            };
            
            if (isUpdateMode) {
                await updateMatriculado(registroData.oid, dataInfo);
                message.success("Registro actualizado con éxito");
            } else {
                await createMatriculado(dataInfo);
                message.success("Registro creado con éxito");
            }

            form.resetFields();
            onSuccess();
            setActiveTabKey("1");
        } catch (err) {
            console.error("Error al guardar:", err);
            message.error("Error al guardar los datos");
        }
    };

    return (
        <div>
            <Form form={form} layout="vertical" onFieldsChange={handleFormChange} onValuesChange={handleValuesChange}>
                <Tabs activeKey={activeTabKey} onChange={handleTabChange} items={tabItems} />
                <div style={{ marginTop: "16px", textAlign: "right" }}>
                    {activeTabKey === "2" && (
                        <>
                            <Button onClick={prevTab} style={{ marginRight: 8 }}>
                                Atrás
                            </Button>
                            <Button type="primary" onClick={handleCreateOrUpdate}>
                                {isUpdateMode ? "Actualizar" : "Crear"}
                            </Button>
                        </>
                    )}
                    {activeTabKey === "1" && (
                        <Button type="primary" onClick={nextTab}>
                            Siguiente
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default FormCaracterizacion;